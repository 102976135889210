<template>
  <CommonPage id="news-detail" title="News Details">
    <component :is="`theme_${themeConfig.landType}`" :requirDataAlready="requirDataAlready"
      :hasNews="hasNews" :newsId="newsId" :newsObj="newsObj" :themeConfig="themeConfig"
      :showDebug="showDebug" :class="`page_is_${currentLanguage}`" @childClicked="executeFunc" />
  </CommonPage>
</template>

<script>

const theme_1 = () => import('@/pages/modules/landing/theme_1.vue') //代码切割
const theme_2 = () => import('@/pages/modules/landing/theme_2.vue') //代码切割
const theme_3 = () => import('@/pages/modules/landing/theme_3.vue') //代码切割
const theme_4 = () => import('@/pages/modules/landing/theme_4.vue') //代码切割
const theme_5 = () => import('@/pages/modules/landing/theme_5.vue') //代码切割
import CommonPage from './CommonPage.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'LandingMain',
  components: {
    theme_1,
    theme_2,
    theme_3,
    theme_4,
    theme_5,
    CommonPage
  },
  data () {
    return {
      newsId: 0,
      newsObj: {},
      requirDataAlready: false,
      hasNews: false,
    };
  },
  computed: {
    ...mapGetters({ //别名版
      themeConfig: "themeConfig",
      adsense: "adsenseConfig",
      showDebug: "showDebug"
    }),
    currentLanguage () {
      return this.themeConfig.isArabia === 1 ? 'ar' : 'en';
    }
  },
  async activated () {
    const newNewsId = Number(this.$route.query.id);
    console.log(newNewsId);
    console.log(this.newsId);
    if (this.newsId !== newNewsId) {
      this.newsId = newNewsId;
      console.log('News ID updated:', this.newsId);

      try {
        await this.getNews();
        this.loadAdSenseScript();
        this.$eventrackFb('show_details_h5', 'expose', {
          website: this.website,
          id: this.newsId,
          pve_cur: '/h5page/news/details/',
        });
      } catch (error) {
        console.error('An error occurred in getList:', error);
      }
    } else {
      console.log('News ID not changed:', this.newsId);
    }

  },
  deactivated () {
    console.log('-----------Component deactivated');
  },
  async mounted () {
    console.log('mounted-------------------');
    const newNewsId = Number(this.$route.query.id);
    if (this.newsId !== newNewsId) {
      this.newsId = newNewsId;
      console.log('News ID updated:', this.newsId);
      try {
        await this.getNews();
        this.loadAdSenseScript();
      } catch (error) {
        console.error('An error occurred in getList:', error);
      }
    } else {
      console.log('News ID not changed:', this.newsId);
    }

    this.$eventrackFb('show_details_h5', 'expose', {
      website: this.website,
      id: this.newsId,
      pve_cur: '/h5page/news/details/',
    });
  },
  beforeDestroy () {
    console.log('beforeDestroy-------------------');
    this.removeAdScript();
  },
  methods: {
    async executeFunc (data) {
      // 接收参数并执行一些操作
      console.log('Feed component button clicked!', data);
      console.log(this.newsId);
      const newNewsId = Number(data);
      if (this.newsId !== newNewsId) {
        this.scrollToTop()
        this.newsId = newNewsId;
        console.log('News ID updated:', this.newsId);
        try {
          await this.getNews();
          this.loadAdSenseScript();
        } catch (error) {
          console.error('An error occurred in getList:', error);
        }
      } else {
        console.log('News ID not changed:', this.newsId);
      }
    },
    goback () {
      this.$router.go(-1);
    },
    initializeComponent () {
      if (!window.adsbygoogle || window.adsbygoogle.length === 0 || !window.adsbygoogle.loaded) {
        console.log("reloading Adsense script.");
        this.loadAdSenseScript();  // 重新加载广告脚本
      }
    },
    scrollToTop () {
      let topHeight =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      let speed = topHeight / 10 > 100 ? topHeight / 10 : 100;
      scrollBy(0, -speed);
      // 模拟鼠标向上滚动事件
      this.scrolldelay = setTimeout(this.scrollToTop, 5);
      // 清除滚动事件，避免无法向下移动
      if (topHeight === 0) {
        clearTimeout(this.scrolldelay);
        this.scrolldelay = null;
      }
    },
    removeAdScript () {
      // 移除广告实例
      const ads = document.querySelectorAll('.adsbygoogle');
      ads.forEach(ad => {
        ad.remove();  // 如果是简单的广告，直接移除可能就足够了
      });

      // 重置 window.adsbygoogle 数组
      window.adsbygoogle = [];

      // 移除广告脚本标签
      const adScript = document.querySelector('script[src="' + this.adsense?.scriptUrl + '"]');
      if (adScript) {
        adScript.remove();
      }
    },
    loadAdSenseScript () {
      console.log('landing loadAdSenseScript func');
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        setTimeout(this.displayAd, 600); // 延迟100毫秒初始化广告
        return; // 如果已加载，直接返回
      }
      this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsense);
      // 判断广告脚本URL是否存在
      if (!this.adsense?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsense.scriptUrl}"]`);
      if (existingScript) {
        console.log('脚本已存在，无需重新添加');
        return;
      }

      const script = document.createElement('script');
      script.src = this.adsense?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
    },
    loadAdWithDelay () {
      setTimeout(this.displayAd, 1500); // 延迟100毫秒初始化广告
    },
    displayAd () {
      // (window.adsbygoogle = window.adsbygoogle || []).push({});
      // 计算总的广告加载次数
      const adPositions = [
        ...this.themeConfig.landAdPos,
        ...this.themeConfig.landFeedAdPos,
        ...this.themeConfig.landFeedInsertAdPos
      ];
      // 对每个位置加载一次广告
      adPositions.forEach(() => {
        console.log('load ads by push...');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    async getNews (id) {
      this.hasNews = false;
      const timestamp = new Date().getTime();
      try {
        const res = await this.$global.server.getNews({
          id: id ? id : this.newsId,
          _: timestamp  // 添加时间戳作为查询参数
        });

        this.requirDataAlready = true;

        if (res.code != 200) {
          console.log('获取失败');
          return;
        }

        this.hasNews = true;
        this.newsObj = {
          title: res.data.title,
          channel: res.data.channel,
          poster: res.data.articleDetails?.[0]?.img,
          publishedTime: res.data.published,
          content: res.data.articleDetails
        };

      } catch (err) {
        this.hasNews = false;
        this.requirDataAlready = true;
        console.error('An error occurred while getting news:', err);
      }
    },
  },

};
</script>
